@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaProMedium.eot');
    src: local('Sofia Pro Medium'), local('SofiaProMedium'),
        url('SofiaProMedium.eot?#iefix') format('embedded-opentype'),
        url('SofiaProMedium.woff2') format('woff2'),
        url('SofiaProMedium.woff') format('woff'),
        url('SofiaProMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaProBlack.eot');
    src: local('Sofia Pro Black'), local('SofiaProBlack'),
        url('SofiaProBlack.eot?#iefix') format('embedded-opentype'),
        url('SofiaProBlack.woff2') format('woff2'),
        url('SofiaProBlack.woff') format('woff'),
        url('SofiaProBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), local('Poppins-Light'),
        url('Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}